// Copyright 2020 Trimble Inc. All Rights Reserved.
// Author: bugra@sketchup.com (Bugra Barin)
// Author: matt@sketchup.com (Matt Gates)

import * as Leaflet from 'leaflet';
import * as magellan from 'magellan-coords';
import Vue from 'vue';
import Cookies from 'js-cookie';

import EosNotificationModal from '../components/eos-notification.modal.vue';
import HelpButton from '../components/help-button.vue';
import ImportSiteModal from '../components/import-site-modal.vue';
import LoggedOutModal from '../components/logged-out-modal.vue';
import MapTypeComponent from '../components/map-type.component.vue';
import RegionImportControls from '../components/region-import-controls.vue';
import '../sass/addlocation.scss';
import ZoomControlsComponent from '../components/zoom-controls.component.vue';
import EventBus from '../typescript/EventBus';

import { AppFeatures, getAppFeatures } from './application-features';
import { CDPAnalyticsHandler as Analytics } from './customer_data_platform/AnalyticsHandler';
import { EVENT_SCHEMA_VERSION, ImportPropertiesDependencies } from './customer_data_platform/AnalyticsInterfacesV1';
import { EntitlementsService } from './entitlements/entitlements.service';
import { ImportResolutionType } from './enums/import-resolution-type.enum';
import { getApplicationNameForPlatform, getPlatform, Platform } from './enums/platform.enum';
import { UserPreferencesStorageKeysEnum } from './enums/user-preferences-storage-keys.enum';
import * as env from './Environment';
import { GeoCodeResultType, GeocoderResponse, ReverseGeocoderResponse } from './GeocoderResponse';
import * as ImportFormatter from './ImportFormatter';
import { Token } from './inject-tokens';
import { ServicesInterface } from './interfaces/ServicesInterface';
import * as I10n from './l10n';
// Needed for Sentry logger.
import { Logger } from './Logger';
import { getLoginInfo, LoginResponse } from './Login';
import { AreaSelectLayer } from './map/area-select-layer';
import { MapTileProviderEnum } from './map/enums/map-tile-provider.enum';
import { MapTypeEnum } from './map/enums/map-type.enum';
import { SatelliteTileProviderEnum } from './map/enums/satellite-tile-provider.enum';
import { MapModel } from './map/map.model';
import { TileGridLayer } from './map/tile-grid-layer';
import { Messages } from './Messages';
import { AddLocationApiService } from './services/add-location-api.service';
import { AppSettingsService } from './services/app-settings.service';
import { BrowserDetailsService } from './services/browser-details.service';
import { StorageVersion } from './services/storage-version.service';
import { UserPreferenceService } from './services/user-preference.service';
import { getTileQtyFromBounds } from './TileGrid';
import { CdpService } from './tracking/cdp.service';
import { generateGainsightIdentity } from './tracking/gainsight-identity.interface';
import { GainsightService } from './tracking/gainsight.service';
import { LaunchEventData } from './tracking/launch-event-data';
import { TrackingEventEnum } from './tracking/tracking-event.enum';
import { TrackingService } from './tracking/tracking.service';
import { getDeviceId, getSessionId } from './Utils';

// The global page object.
let addLocationPageInstance = null;

const LAST_VIEW_COOKIE_NAME = 'lastView';
const LAST_ADDRESS_COOKIE_NAME = 'lastAddress';
const LAST_SERVICE_PROVIDER_COOKIE_NAME = 'lastServiceProvider';

export function InitPage(): AddLocationPage {
  document.title = I10n.$t('Add Location');

  // The instance of our main UI class.
  addLocationPageInstance = new AddLocationPage();
  // Send init message back to the calling client.
  if (window !== parent) {
    // Running in an iframe?
    // Presumably running in P11.
    window.addEventListener('message', addLocationPageInstance.messageHandler.bind(addLocationPageInstance));
    addLocationPageInstance.postMessageToP11(Messages.INIT_MESSAGE);
  } else {
    // Presumably running in the client.
    window.location.href = 'skp:' + Messages.INIT_MESSAGE;
  }
  return addLocationPageInstance;
}

// Called by the desktop clients (not P11), do not change!
export function SetServicesImpl(services: ServicesInterface): void {
  addLocationPageInstance.attemptToInitializeAddLocation(services);
}

class AddLocationPage {
  private mapModel_: MapModel;
  private tileGridLayer: TileGridLayer;
  private areaSelectLayer: AreaSelectLayer;
  private grabSkpAction_ = 'skp:' + Messages.GRAB_MESSAGE + '@';
  private loginInfo_: LoginResponse;
  private services_: ServicesInterface;
  private modelOriginLatLong_: Leaflet.LatLng;
  private importResolution_: number | undefined = UserPreferenceService.importLevel;
  private regionImported_ = false;
  private regionSelected_ = false;
  private appFeatures_: AppFeatures | undefined = undefined;
  private tilesSelectedQty_ = 0;
  private showMapTypeControls_ = true;
  private analytics_: Analytics;
  private exceptionLogger: Logger;
  private platform: Platform;
  private readonly importZoomLevelMin_ = 14;
  private readonly updateTileGrid: () => void;
  private readonly trackingService: TrackingService = new TrackingService();

  private vueInstance_: Vue;

  constructor() {
    this.render();
    this.updateTileGrid = () => {
      if (this.tileGridLayer.isEnabled) {
        this.updateTileGridWithUserPreference();
      }
    };
  }

  private render() {
    EventBus.$on('update:zoom', (newZoom: number) => {
      this.mapModel_.map.setZoom(newZoom);
    });

    EventBus.$on('update:mapType', (mapType: MapTypeEnum) => {
      this.updateMapType(mapType);
      if (this.isRegionSelectionEnabled) {
        this.mapModel_.map.closePopup();
        this.setTilesSelectedData();
      }
    });
    EventBus.$on('update:selectRegion', () => {
      this.trackingService.send(TrackingEventEnum.ClickedSelectRegion, {
        zoom_level: this.mapModel_.map.getZoom(),
      });
      this.setImportZoomLevel(this.initImportLevel);
      this.startSelect();
      this.updateTileGridWithUserPreference();
      // Redraw the grid when a map move completes.
      this.mapModel_.map.on('moveend', this.updateTileGrid);
    });
    EventBus.$on('update:importRegion', () => {
      this.trackingService.send(TrackingEventEnum.ClickedTileImport, {
        zoom_level: this.mapModel_.map.getZoom(),
        import_level: this.importResolution,
        provider: this.mapModel_.activeTileProvider,
      });
      this.doImport();
    });
    EventBus.$on('update:cancelSelectRegion', async () => {
      this.trackingService.send(TrackingEventEnum.CanceledSelectRegion);
      // Analytics logic.
      const reverseGeocodeData = await this.getReverseGeocodeData();

      if (!this.analytics_) {
        this.exceptionLogger.logExceptionMessage(
          'Analytics is not setup, no analytic data to collect for cancelling region select',
        );
      } else {
        const importData = this.getDataForAnalyticsImports(reverseGeocodeData);
        this.analytics_.setImportPropertiesObj(importData); // Use current map data to fill in props.
        this.analytics_.sendImportCancelEvent();
      }

      // Remove tile grid listener.
      this.mapModel_.map.off('moveend', this.updateTileGrid);
      this.cancelSelect();
    });
    EventBus.$on('update:importLevel', (newImportLevel: number) => {
      this.trackingService.send(TrackingEventEnum.ChangedImportLevel, {
        import_level: newImportLevel,
      });
      this.handleNewImportLevel(newImportLevel);
    });
    EventBus.$on('update:tileGridToggle', this.toggleTileGrid.bind(this));
    EventBus.$on('update:satelliteProvider', (provider: SatelliteTileProviderEnum) => {
      this.trackingService.send(TrackingEventEnum.SelectedProvider, { provider });
      this.handleTileProviderChange(provider);
      this.setTilesSelectedData();
    });

    // These two listeners will report errors in prod to Sentry for logging.
    window.addEventListener('error', (error: ErrorEvent) => {
      this.exceptionLogger.logException(error.error);
    });

    window.addEventListener('unhandledrejection', (error: PromiseRejectionEvent) => {
      this.exceptionLogger.logUnhandledRejection(error);
    });

    Vue.use(I10n);
    Vue.component('help-button', HelpButton);
    Vue.component('map-type', MapTypeComponent);
    Vue.component('region-import-controls', RegionImportControls);
    Vue.component('zoom-controls', ZoomControlsComponent);

    Vue.component('import-site-modal', ImportSiteModal);
    Vue.component('logged-out-modal', LoggedOutModal);
    Vue.component('eos-notification-modal', EosNotificationModal);

    this.vueInstance_ = new Vue({
      computed: {
        isLoggedIn() {
          return this.loginInfo?.trimbleid != null;
        },
        shallowZoomLevel() {
          return this.zoomLevel < this.minImportLevel;
        },
      },
      el: '#app',
      provide: {
        [Token.TrackingService]: this.trackingService,
      },
      data: {
        appFeatures: this.appFeatures_,
        map: this.mapModel_,
        importLevel: undefined,
        initialized: false, // Need leaflet etc to be set up before setting to true.
        load_error: false,
        services: this.services_,
        loginInfo: this.loginInfo_,
        minImportLevel: this.importZoomLevelMin_,
        regionImported: this.regionImported_,
        regionSelected: false,
        selectedMapType: undefined,
        selectedTileProvider: undefined,
        showMapTypeControls: this.showMapTypeControls_,
        showTileGrid: false,
        tilesSelectedQty: this.tilesSelectedQty_,
        zoomLevel: 10,
        zoomLevelMax: 18,
        zoomLevelMin: 6,
        lang: new URL(location.href).searchParams.get('hl') ?? 'en',
        importSitePopupVisible: false,
        platform: this.platform,
      },
      beforeCreate() {
        StorageVersion.execAndBump(() => {
          UserPreferenceService.reset(UserPreferencesStorageKeysEnum.IMPORT_LEVEL);
        });
      },
    });
  }

  private updateMapType(mapType: MapTypeEnum) {
    this.mapModel_.activeMapType = mapType;
    this.mapModel_.activateBaseLayer(this.mapModel_.mapTypeToProvider(mapType));
    this.mapModel_.map.fire('zoomlevelschange');
    this.updateTileGridWithUserPreference();
  }

  private updateSatelliteProvider(provider: SatelliteTileProviderEnum) {
    if (this.mapModel_.activeMapType !== MapTypeEnum.satellite) {
      throw new Error('Cannot set satellite provider when satellite is not selected');
    }

    this.mapModel_.activeSatelliteType = provider;
    this.mapModel_.activeTileProvider = MapModel.satelliteToMapTileProvider(provider);
    this.mapModel_.activateBaseLayer(this.mapModel_.activeTileProvider);

    this.mapModel_.map.fire('zoomlevelschange');
  }

  private getDataForAnalyticsImports(reverseGeocodeData: ReverseGeocoderResponse): ImportPropertiesDependencies {
    return {
      centerpoint: this.mapModel_.map.getCenter(),
      cityState: reverseGeocodeData.City,
      country: reverseGeocodeData.Country,
      importType: this.analytics_?.getTileProviderTypeFromMapProvider(this.mapModel_.activeTileProvider),
      tileQuantity: this.tilesSelectedQty_,
      zoom: this.mapModel_.map.getZoom(),
      zoomForImport: this.importResolution,
    };
  }

  private handleNewImportLevel(importLevel: number) {
    UserPreferenceService.importLevel = importLevel;
    this.setImportZoomLevel(importLevel);
    if (this.tileGridLayer.isEnabled) {
      this.updateTileGridWithUserPreference();
    }
    if (this.isRegionSelectionEnabled) {
      this.setTilesSelectedData();
    }
  }

  private handleTileProviderChange(provider: SatelliteTileProviderEnum) {
    // User selected Bing or hasn't chosen a provider.
    this.updateSatelliteProvider(provider);
    // Clear any popups if they are present.
    this.mapModel_.map.closePopup();

    this.updateTileGridWithUserPreference();
  }

  private updateTileGridWithUserPreference() {
    this.setTileGrid(!!UserPreferenceService.tileGridPreference, this.mapModel_.map.getZoom(), this.importResolution);
  }

  private toggleTileGrid() {
    const isEnabled = !UserPreferenceService.tileGridPreference;
    UserPreferenceService.tileGridPreference = isEnabled;
    this.updateTileGridWithUserPreference();
    this.trackingService.send(TrackingEventEnum.ToggledTileBoundaries, {
      tile_grid_visible: isEnabled,
    });
    Vue.set(this.vueInstance_.$data, 'showTileGrid', isEnabled);
  }

  private async setLoginInfo() {
    const loginInfo = await getLoginInfo();
    this.loginInfo_ = loginInfo;
    Vue.set(this.vueInstance_, 'loginInfo', loginInfo);
  }

  /**
   * Sets the zoom level for the map region the user will import.
   */
  private setImportZoomLevel(newImportZoomLevel: number): void {
    this.importResolution_ = newImportZoomLevel;
    this.updateImportZoomLevelUIData();
  }

  private async setupAnalytics(
    platform: Platform,
    entitlementsService: EntitlementsService,
    logger: Logger,
  ): Promise<void> {
    let sku: string | undefined;
    if (platform !== Platform.Schools) {
      sku = await entitlementsService.getSku();
    }

    const deviceId = getDeviceId(this.services_, platform);
    const sessionId = getSessionId(this.services_, platform);

    const gainsightService = new GainsightService(
      generateGainsightIdentity(sku, this.loginInfo_, this.services_, platform, logger),
      logger,
    );

    const cdpService = new CdpService(
      this.services_.sketchup_cdp_url,
      this.services_.license,
      deviceId,
      sessionId,
      logger,
    );

    this.trackingService.addTrackingService(gainsightService, cdpService);

    this.analytics_ = new Analytics(
      getApplicationNameForPlatform(platform),
      this.services_.license,
      this.services_.client_version,
      sku,
      deviceId,
      sessionId,
    );

    // Send launched app event
    this.analytics_
      .sendLaunchedAppEvent()
      .then(() => {})
      .catch(e => console.error('Failed to initialize analytics', e));

    const browserDetails = BrowserDetailsService.browserDetailsForAgent(window.navigator.userAgent);

    this.trackingService.send(
      TrackingEventEnum.LaunchedApplication,
      new LaunchEventData(this.services_, browserDetails, platform, sku),
    );

    // Listen only once for mouse down and move to send map pan event
    const onMoveEnd = (previousCenterPoint: { lng: number; lat: number }) => {
      const currentCenterPoint = this.mapModel_.map.getCenter();
      if (currentCenterPoint.lng !== previousCenterPoint.lng || currentCenterPoint.lat !== previousCenterPoint.lat) {
        this.trackingService.send(TrackingEventEnum.ChangedMapPosition);
        this.mapModel_.map.off('mousedown');
      }
    };

    this.mapModel_.map.on('mousedown', () => {
      const currentCenterPoint = Object.assign({}, this.mapModel_.map.getCenter());
      this.mapModel_.map.once('moveend', () => onMoveEnd(currentCenterPoint));
    });

    // Add handler for closed app event, which will fire only when Add Location closes without
    // doing an import.
    window.addEventListener('unload', () => {
      this.trackingService.sendAsync(TrackingEventEnum.ClosedApplication, {
        event_schema_version: EVENT_SCHEMA_VERSION,
      });
      this.sendClosedAppEventOnCloseWithoutImport();
    });
  }

  private sendClosedAppEventOnCloseWithoutImport() {
    if (!this.regionImported_) {
      this.analytics_.sendClosedAppEvent(false);
    }
  }

  attemptToInitializeAddLocation(services: ServicesInterface): void {
    this.initializeAddLocation(services).catch(error => {
      this.vueInstance_.$data.load_error = true;
      if (this.exceptionLogger) {
        this.exceptionLogger.logExceptionMessage('Error occurred during initialisation of AddLocation.');
        this.exceptionLogger.logException(error);
      } else {
        console.error('Error occurred during initialisation of AddLocation. Giving up', error);
      }
    });
  }

  /**
   * Sets the services object retrieved from the server. This is called by
   * the hosting client either by eval'ing a JS string (in the case of desktop
   * clients) or via a PostMessage (in the case of P11). These callers do not
   * need to await this call.
   *
   * @param services the given services object.
   */
  private async initializeAddLocation(services: ServicesInterface): Promise<void> {
    this.vueInstance_.$data.initialized = true;
    this.exceptionLogger = Logger.init(services.stack);
    this.platform = getPlatform(services, document.referrer);
    Vue.set(this.vueInstance_.$data, 'platform', this.platform);

    this.services_ = services;
    const entitlementsService = new EntitlementsService(env.getActiveEnvironment(), this.platform);
    const activeFeatures = await entitlementsService.getActivatedFeatures();
    this.appFeatures_ = getAppFeatures(this.platform, activeFeatures);
    Vue.set(this.vueInstance_, 'services', this.services_);

    // initialise the map
    this.mapModel_ = new MapModel(
      services,
      { lastServiceProviderCookieName: LAST_SERVICE_PROVIDER_COOKIE_NAME },
      this.appFeatures,
    );
    this.tileGridLayer = new TileGridLayer(this.mapModel_.map);
    this.areaSelectLayer = new AreaSelectLayer(this.mapModel_);
    this.initMapListeners(this.mapModel_.map);

    Vue.set(this.vueInstance_, 'map', this.mapModel_);

    this.setInitialMapView(this.mapModel_);

    // Make the necessary UI updates based on the available features and user being logged in.
    await this.setLoginInfo();

    this.updateAppFeaturesSettings();

    // There is an order dependency to this call. It must occur after updateAppFeaturesSettings().
    this.setupAnalytics(this.platform, entitlementsService, this.exceptionLogger);
  }

  private async getReverseGeocodeData(): Promise<ReverseGeocoderResponse> {
    const centerpoint: Leaflet.LatLng = this.mapModel_.map.getCenter();
    return await AddLocationApiService.reverseGeocode(centerpoint.lng, centerpoint.lat, I10n.getCurrentLanguage());
  }

  private updateAppFeaturesSettings() {
    const tileGridPreference = UserPreferenceService.tileGridPreference;
    const tileGridAvailable = this.appFeatures.tileBoundaries;

    this.setTileGrid(
      tileGridAvailable && tileGridPreference !== false,
      this.mapModel_.map.getZoom(),
      this.importResolution,
    );
    Vue.set(this.vueInstance_.$data, 'appFeatures', this.appFeatures);
  }

  private updateImportZoomLevelUIData() {
    Vue.set(this.vueInstance_.$data, 'importLevel', this.importResolution);
  }

  private updateRegionImportUIData() {
    Vue.set(this.vueInstance_.$data, 'regionSelected', this.isRegionSelectionEnabled);
    Vue.set(this.vueInstance_.$data, 'regionImported', this.regionImported_);
    Vue.set(this.vueInstance_.$data, 'showTileGrid', !!UserPreferenceService.tileGridPreference);
  }

  private updateZoomUIData() {
    // Zoom data.
    Vue.set(this.vueInstance_.$data, 'zoomLevel', this.mapModel_.map.getZoom());
    Vue.set(this.vueInstance_.$data, 'zoomLevelMax', this.mapModel_.map.options.maxZoom);
    Vue.set(this.vueInstance_.$data, 'zoomLevelMin', this.mapModel_.map.options.minZoom);
  }

  private updateTileProviderUIData() {
    Vue.set(this.vueInstance_.$data, 'selectedMapType', this.mapModel_.activeMapType);
    Vue.set(this.vueInstance_.$data, 'selectedTileProvider', this.mapModel_.activeSatelliteType);
  }

  private updateTileData() {
    Vue.set(this.vueInstance_.$data, 'tilesSelectedQty', this.tilesSelectedQty_);

    if (this.areaSelectLayer.areaSelect) {
      this.areaSelectLayer.areaSelect.toggleAreaHandleWarning(
        this.tilesSelectedQty_ > MapModel.MAX_TILE_IMPORT_QUANTITY,
      );
    }
  }

  private initMapListeners(map: Leaflet.Map): void {
    const updateUI = () => {
      if (!this.appFeatures.satelliteImport && AppSettingsService.isFirstTimeUser) {
        // todo can we remove this weird hack?
        this.updateMapType(MapTypeEnum.streetMap);
      }
      this.updateZoomUIData();
      this.updateImportZoomLevelUIData();
      this.updateTileProviderUIData();
    };

    updateUI();

    map.on('zoomlevelschange', () => {
      updateUI();
    });

    // Listen to zoom events so that we can show the grab area when appropriate and
    // update the zoom controls.
    map.on('zoomend', (_: Leaflet.LeafletEvent) => {
      if (!this.canGrabAtThisZoomLevel()) {
        this.cancelSelect();
      }
      // The order of the following method calls matters.
      this.updateZoomUIData();
    });
  }

  private setTileGrid(enabled: boolean, zoom: number, importLevel?: number) {
    const enabledTileGrid: boolean =
      enabled &&
      zoom >= this.importZoomLevelMin_ &&
      this.appFeatures.tileBoundaries &&
      this.mapModel_.activeTileProvider !== MapTileProviderEnum.streetMap &&
      this.isRegionSelectionEnabled;

    this.tileGridLayer.update(enabledTileGrid, zoom, importLevel || zoom);
  }

  private setInitialMapView(mapModel: MapModel) {
    // Get map center from URL parameters, or from cookies if no params were
    // sent. If there are neither map center params or cookies, then show the
    // entire world on the map and show the splash screen.
    const url = new URL(window.location.href);
    let centerLat = 0;
    let centerLng = 0;
    const centerParam = url.searchParams.get('center');
    let zoom: number;
    if (centerParam) {
      const latLong = centerParam.split(',', 2);
      centerLat = Number(latLong[0]);
      centerLng = Number(latLong[1]);

      // If the center param was passed, then this session is adding a new
      // terrain grab to a model that has already been geolocated. In such a
      // case, remember this location in a special variable so we can show a
      // warning message if the user takes a snapshot more than 1000 meters
      // away.
      this.modelOriginLatLong_ = new Leaflet.LatLng(centerLat, centerLng);

      const zoomParam = url.searchParams.get('zoom');
      if (zoomParam) {
        zoom = parseInt(zoomParam, 10);
      }
      mapModel.decorations.addMapPin(this.modelOriginLatLong_);
    } else {
      const lastView = Cookies.get(LAST_VIEW_COOKIE_NAME);
      if (lastView != null) {
        const latLongZoom = (<string>lastView).split(',');
        centerLat = parseFloat(latLongZoom[0]);
        centerLng = parseFloat(latLongZoom[1]);
        zoom = parseInt(latLongZoom[2], 10);

        const lastAddress = Cookies.get(LAST_ADDRESS_COOKIE_NAME);
        if (lastAddress != null) {
          this.addressInput_.value = <string>lastAddress;
        }
      } else {
        // Try to locate our position and zoom there.
        AddLocationApiService.geolocate()
          .then(response => {
            mapModel.setMapView([response.latitude, response.longitude]);
            this.setImportZoomLevel(this.initImportLevel);
          })
          .catch(() => this.setImportZoomLevel(this.initImportLevel));
        return;
      }
    }

    mapModel.setMapView([centerLat, centerLng], zoom);
    this.setImportZoomLevel(this.initImportLevel);
  }

  private get initImportLevel(): number {
    return UserPreferenceService.importLevel || this.mapModel_.activeTileProviderService.end_zoom;
  }

  /*
   * Saves the current map view in temporary cookies.
   */
  saveMapView() {
    if (this.mapModel_) {
      const center = this.mapModel_.map.getCenter();
      this.setTemporaryCookie(
        LAST_VIEW_COOKIE_NAME,
        center.lat + ',' + center.lng + ',' + this.mapModel_.map.getZoom(),
      );
      this.setTemporaryCookie(LAST_ADDRESS_COOKIE_NAME, this.addressInput_.value);
      this.setTemporaryCookie(LAST_SERVICE_PROVIDER_COOKIE_NAME, this.mapModel_.activeTileProvider);
    }
  }

  /**
   * Tries to parse a LatLng out of a given string. Returns null on failure.
   */
  private parseLatLng(text: string): Leaflet.LatLng {
    text = text.trim();
    let delimPos = text.indexOf(',');
    if (delimPos < 0) {
      delimPos = text.indexOf(' ');
    }
    if (delimPos > 0) {
      const latStr = text.substring(0, delimPos).trim();
      const lngStr = text.substring(delimPos + 1).trim();
      const lat = magellan(latStr).latitude();
      const lng = magellan(lngStr).longitude();
      if (lat && lng) {
        return Leaflet.latLng(lat.toDD(), lng.toDD());
      }
    }
    return null;
  }

  findAddress() {
    if (!this.mapModel_) {
      // avoid race conditions where search can be done before the map model has loaded
      // in reality this should not happen, but in practice it has been witnessed, possibly when there is a delay in entitlements
      console.warn('Cannot perform address search while the map has not been initialised');
      return;
    }

    const address = this.addressInput_.value;
    if (address === '') {
      return;
    }

    // Check if this is a lat/lng text.
    const latlng = this.parseLatLng(address);
    if (latlng !== null) {
      this.mapModel_.setMapView(latlng, this.mapModel_.map.getMaxZoom() - 2);
      return;
    }

    // Treat it as an actual address and make a /geocode call.
    AddLocationApiService.geocode(address, I10n.getCurrentLanguage()).then(
      (responseJSON: Array<GeocoderResponse>) => {
        if (responseJSON.length > 0) {
          this.addressInput_.value = responseJSON[0].Label;
          // ALK doesn't provide a way to determine zoom level so for now we'll set a default.
          const resultType = responseJSON[0].ResultType;
          const default_zoom = this.determineZoomLevel(resultType);
          const mapView = responseJSON[0].MapView;
          const mapCenter = Leaflet.latLng(mapView.Lat, mapView.Lon);
          this.mapModel_.setMapView(mapCenter, default_zoom);
          this.trackingService.send(TrackingEventEnum.ChangedAddress, {
            address: this.addressInput_.value,
          });
        } else {
          alert(I10n.$t('Sorry, no matches.'));
        }
      },
      () => {
        alert(I10n.$t('An error occurred while finding this address.'));
      },
    );
  }

  private determineZoomLevel(resultType: GeoCodeResultType): number {
    switch (resultType) {
      case GeoCodeResultType.COUNTRY:
        return 5;
      case GeoCodeResultType.STATE:
        return 8;
      case GeoCodeResultType.COUNTY:
      case GeoCodeResultType.CITY:
      case GeoCodeResultType.ZIP:
        return 13;
      default:
        return 18;
    }
  }

  private checkZoomLevel() {
    return this.canGrabAtThisZoomLevel();
  }

  /**
   * Returns true if the current zoom level is high enough to do a grab.
   */
  private canGrabAtThisZoomLevel(): boolean {
    return this.mapModel_.map.getZoom() >= this.importZoomLevelMin_;
  }

  /**
   * Starts the selection mode.
   */
  startSelect() {
    if (!this.checkZoomLevel()) {
      return;
    }

    this.regionSelected_ = true;
    this.areaSelectLayer.update(true);

    // Clean up the parts of the UI that have no meaning in selection mode.

    this.updateRegionImportUIData();
    this.setTilesSelectedData();
    this.mapModel_.map.on('areaSelectChange', () => this.setTilesSelectedData());
  }

  /**
   * Cancels the selection mode and reactivates the search UI.
   */
  cancelSelect() {
    this.regionSelected_ = false;
    this.areaSelectLayer.update(false);
    this.setTileGrid(false, this.mapModel_.map.getZoom());
    this.updateRegionImportUIData();
    this.clearTilesSelectedData();
  }

  private setTilesSelectedData() {
    this.tilesSelectedQty_ = getTileQtyFromBounds(
      this.areaSelectLayer.bounds,
      this.mapModel_.map.getZoom(),
      this.importResolution,
    );
    this.updateTileData();
  }

  /**
   * Resets the tiles selected data to default values.
   */
  private clearTilesSelectedData() {
    this.tilesSelectedQty_ = 0;
  }

  /**
   * Initiates the import when the user clicks the send button. Sends a skp
   * action back to the client indicating the selected area.
   */
  async doImport() {
    if (!this.checkZoomLevel()) {
      return;
    }

    // Make reverse geocoding call for the center of the grab area.
    const centerpoint = this.mapModel_.map.getCenter();
    const reverseGeocodeData = await AddLocationApiService.reverseGeocode(
      centerpoint.lng,
      centerpoint.lat,
      I10n.getCurrentLanguage(),
    );

    // Check to see if this snapshot is being done more than 1000 meters from
    // an existing snapshot.
    const center = this.mapModel_.map.getCenter().wrap();
    if (this.modelOriginLatLong_) {
      const distance = center.distanceTo(this.modelOriginLatLong_);
      if (distance > 1000) {
        const addressTooFarMsg = I10n.$t(
          'This SketchUp model is ' +
            'already georeferenced at a location which is more than 1,000 ' +
            'meters from the location you have selected. This may result in ' +
            'poor alignment. Continue?',
        );
        const continueGrab = window.confirm(addressTooFarMsg);
        if (continueGrab === false) {
          // Return since the user does not want to continue.
          return;
        }
      }
    }
    Vue.set(this.vueInstance_.$data, 'importSitePopupVisible', true);
    this.importResolution_ = this.closestValidImportResolution(this.importResolution);
    const serviceIndex = this.mapModel_.findMapServiceIndexByProvider(this.mapModel_.activeTileProvider);

    const bounds = this.areaSelectLayer.bounds;
    // Make sure bounds are between -180 and +180.
    const northEastLatLng = bounds.getNorthEast().wrap();
    const southWestLatLng = bounds.getSouthWest().wrap();

    const importConfig: ImportFormatter.ImportConfig = {
      center: { lat: center.lat, long: center.lng },
      northEast: { lat: northEastLatLng.lat, long: northEastLatLng.lng },
      serviceIndex: serviceIndex,
      southWest: { lat: southWestLatLng.lat, long: southWestLatLng.lng },
      terrainPointCount: 2500,
      tileImportId: '', // left blank for backwards compatibility, this was used to identify purchased tiles from nearmap
      useMultipleTextures: true,
      zoom: this.mapModel_.map.getZoom(),
      zoomForImport: this.importResolution,
    };

    const dataObj = ImportFormatter.buildDataObjectForImport(importConfig);

    // Get the data so we can send an import event to Analytics.
    if (!this.analytics_) {
      this.exceptionLogger.logExceptionMessage('Analytics is not setup, no analytic data to collect for import');
    } else {
      const importData = this.getDataForAnalyticsImports(reverseGeocodeData);
      this.analytics_.setImportPropertiesObj(importData);
      this.analytics_.sendImportEvent();
      this.analytics_.sendClosedAppEvent(true);
    }

    // Function that sends results to SketchUp.
    const sendToSketchUp = (dataObjForGrab: any) => {
      const data = JSON.stringify(dataObjForGrab);
      if (this.platform === Platform.Desktop) {
        // No need to call encodeURIComponent on skp action data. Or we seem
        // to get double encoding, which messes up any unicode characters.
        window.location.href = this.grabSkpAction_ + data;
      } else {
        this.postMessageToP11(Messages.GRAB_MESSAGE, data);
      }
      // Page will be closing, save this view.
      this.saveMapView();
    };

    // Is passed as Prop to Vue Component so UI can update as needed after grab is kicked off.
    this.regionImported_ = true;
    this.updateRegionImportUIData();
    // Show the busy mask.
    document.getElementById('busy_mask').classList.remove('invisible');

    if (
      reverseGeocodeData.City &&
      reverseGeocodeData.City.trim().length > 0 &&
      reverseGeocodeData.Country &&
      reverseGeocodeData.Country.trim().length > 0
    ) {
      // Add reverse geocode data to data object.
      dataObj['city'] = reverseGeocodeData.City;
      dataObj['country'] = reverseGeocodeData.Country;
      sendToSketchUp(dataObj);
    } else {
      // Reverse geocoding failed but we'll still finish the grab, but leaving
      // the city and country information empty. This is preferrable to
      // failure.
      sendToSketchUp(dataObj);
    }
  }

  get importResolution(): number | undefined {
    const userDefinedResolution = this.appFeatures.resolutionType === ImportResolutionType.UserSpecified;
    const satelliteSelection = this.mapModel_.activeTileProvider !== MapTileProviderEnum.streetMap;
    return userDefinedResolution && satelliteSelection ? this.importResolution_ : undefined;
  }

  private postMessageToP11(messageKey: string, data: string) {
    const messageObj = { key: messageKey, data: data };
    parent.postMessage(messageObj, '*');
  }

  /**
   * Handler for messages posted to the iframe.
   * Note (brandon): Wnen marked private this function is flagged by 'noUnusedLocals'
   * when compiling although it is used in 'InitPage()' but is bound. Marking as public satisfies
   * the compilier.
   */
  messageHandler(event: MessageEvent): void {
    if (!new URL(event.origin).hostname.endsWith('.sketchup.com')) {
      return;
    }

    if (event.data['key'] === Messages.SERVICES_MESSAGE) {
      this.attemptToInitializeAddLocation(JSON.parse(event.data['data']));
    }
  }

  private get appFeatures(): AppFeatures {
    if (this.appFeatures_ === undefined) {
      throw new Error('AppFeatures not defined');
    }
    return this.appFeatures_;
  }

  private setTemporaryCookie(name: string, value: string) {
    // remove any old cookies on the un-dotted domain
    // this is a temporary measure to avoid the user having two cookies on P11
    // which would cause a bug where the last user actions would not be preserved
    Cookies.remove(name, { path: '/' });
    // We need to set the domain prefixed with a `.`, because SketchUp in between closing and reopening
    // somehow sets a server cookie (indicated by the `.` prefix) and you end up with two cookies when
    // the cookie gets reset by AddLocation.
    Cookies.set(name, value, { expires: 7 /* days*/, path: '/', domain: `.${window.location.hostname}` });
  }

  private closestValidImportResolution(importResolution: number): number {
    const maxZoom = this.mapModel_.activeTileProviderService.end_zoom;
    return importResolution > maxZoom ? maxZoom : importResolution;
  }

  private get isRegionSelectionEnabled(): boolean {
    return this.regionSelected_;
  }

  private get addressInput_(): HTMLInputElement {
    return <HTMLInputElement>document.getElementById('address');
  }
}

function bootstrapAddLocation() {
  // Desktop clients need this global reference to the SetServices function
  window['SetServices'] = SetServicesImpl;

  // The html has references to this global page variable.
  window['addLocationPageInstance'] = InitPage();
  // This is a bit questionable. I wanted to do unbeforeunload but that doesn't
  // seem to work in an iframe.
  window.onunload = () => {
    addLocationPageInstance.saveMapView();
  };
}

// Bootstrap the page after loading the language.
I10n.initializeL10n().then(() => bootstrapAddLocation());
