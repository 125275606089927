// All of the known Trimble EMS featureIds that are targeted at AddLocation
export enum AddlocationFeatureIds {
  Free = 'FEA-SKP-ADL-FREE',
  Silver = 'FEA-SKP-ADL-SLVR',
  Gold = 'FEA-SKP-ADL-GOLD',
  Platinum = 'FEA-SKP-ADL-PLTN',
  Trial = 'FEA-SKP-ADL-TRL',

  // todo remove this as part of FE-969
  LargeAreaImport = 'FEA-SKP-ADL-LAI',
}
