// Copyright 2020 Trimble Inc. All Rights Reserved.
// Author: Cole Carroll (cole@sketchup.com)
// Contains functionality related to sending events to the Analytics Service.
import * as env from '../Environment';
import { Stacks } from '../enums/stacks.enum';
import { CDPEvent } from '../tracking/cdp-event';

const DEV_ANALYTICS_URL = 'https://dev-api.sketchup.com/notifications/v1/sketchup';
const STG_ANALYTICS_URL = 'https://stg-api.sketchup.com/notifications/v1/sketchup';
const PROD_ANALYTICS_URL = 'https://api.sketchup.com/notifications/v1/sketchup';

/**
 * Sends an Add Location CDP event to the SketchUp Analytics Service.
 */
export async function sendEvent(event: CDPEvent): Promise<void> {
  await fetch(getAnalyticsUrlForStack(), {
    method: 'POST',
    body: JSON.stringify(event),
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
  });
}

/**
 * Returns the appropriate SketchUp Analytics Service endpoint URL for the active environment.
 */
export function getAnalyticsUrlForStack(): string {
  const stack = env.getActiveEnvironment();
  switch (stack) {
    case Stacks.Dev:
      return DEV_ANALYTICS_URL;
    case Stacks.Stg:
      return STG_ANALYTICS_URL;
    default:
      return PROD_ANALYTICS_URL;
  }
}

// TODO(Cole) - CWS-1089 - Update this implementation to reflect what is currently in P11 so it
// works across all supported browsers.
/**
 * Sends a Closed Add Location CDP event to the SketchUp Analytics Service. Called when user
 * closes the Add Location window without doing an import.
 */
export function sendClosedAppWithoutImportEvent(event: CDPEvent, clientName: string): void {
  try {
    const stringifiedEvent = JSON.stringify(event);
    if (!isSendBeaconSupported() || clientName === 'su-desktop') {
      const xhrReq = new XMLHttpRequest();
      // False designates this request as synchronous.
      xhrReq.open('POST', getAnalyticsUrlForStack(), false);
      // We must specify that this CORS request is sent with cookies so
      // api.sketchup.com can identify the user using their SID/SSID.
      xhrReq.withCredentials = true;
      xhrReq.setRequestHeader('Content-Type', 'application/json');
      xhrReq.send(stringifiedEvent);
    } else {
      // CORS does not permit sending application/json. Relay through a proxy when sendBeacon is
      // used to send JSON to the analytics service.
      const success = navigator.sendBeacon('/addlocation/v1.0/event', stringifiedEvent);
      if (!success) {
        throw new Error('Sending Closed Add Location event via send beacon failed.');
      }
    }
  } catch (e) {
    // For now, fire and forget.
  }
}

function isSendBeaconSupported(): boolean {
  return navigator.sendBeacon != null;
}
