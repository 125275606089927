import { isValidLargeAreaImportActivation } from '../Entitlements';
import { Platform } from '../enums/platform.enum';
import { Stacks } from '../enums/stacks.enum';

import { ActivationDto, ActivationsResponseDto } from './activations-response.dto';
import { AddlocationFeatureIds } from './addlocation-feature-ids.enum';

// Entitlements url for different stacks.

export class EntitlementsService {
  constructor(
    private readonly stack: Stacks,
    private readonly platform: Platform,
  ) {}

  async getSku(featureFilter: string = 'FEA-SKP-ADL'): Promise<string> {
    const response = await this.fetchActivations(featureFilter);
    if (!response.ok) {
      throw new Error(
        `Error retrieving entitlements at url=${this.getActivationUrl(featureFilter)}, unexpected response code ${
          response.status
        }`,
      );
    }
    const activationsResponse: ActivationsResponseDto = await response.json();
    const validActivations: Array<ActivationDto> = EntitlementsService.extractValidActivations(activationsResponse);
    const anyActivation: Array<ActivationDto> = activationsResponse.activations;
    const activations: Array<ActivationDto> = validActivations.length ? validActivations : anyActivation;
    if (activations.length === 0) {
      throw new Error('There are no activations');
    }
    const latestActivation: ActivationDto = EntitlementsService.getLatestActivation(activations);
    return latestActivation.sku;
  }

  async getActivatedFeatures(): Promise<Set<string>> {
    if (this.platform === Platform.Schools) {
      // schools does not require a TID login, and no students will have any entitlements
      return new Set();
    }

    // todo remove this as part of FE-969
    const maybeLargeAreaImportFeatures = await this.getActivationFeatures(AddlocationFeatureIds.LargeAreaImport);
    if (maybeLargeAreaImportFeatures.size > 0) {
      return maybeLargeAreaImportFeatures;
    }

    return await this.getActivationFeatures('FEA-SKP-ADL');
  }

  private async getActivationFeatures(featureFilter: string): Promise<Set<string>> {
    const url = `${this.getBaseUrl(this.stack)}/api/v1/activations?featureFilter=${featureFilter}`;
    const response = await fetch(url, { credentials: 'include', headers: [['Accept', 'application/json']] });
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        // user is not logged in, return no entitlements
        return new Set();
      } else {
        throw new Error(`Error retrieving entitlements at url=${url}, unexpected response code ${response.status}`);
      }
    }

    const responseJson = await response.json();
    const validFeatureIds = EntitlementsService.extractValidFeatureIds(responseJson);
    // todo remove this as part of FE-969
    if (!isValidLargeAreaImportActivation(responseJson)) {
      validFeatureIds.delete(AddlocationFeatureIds.LargeAreaImport);
    }
    return validFeatureIds;
  }

  static getLatestActivation(activations: Array<ActivationDto>): ActivationDto {
    return activations.sort(
      (a: ActivationDto, b: ActivationDto) =>
        new Date(b.activationDate).getTime() - new Date(a.activationDate).getTime(),
    )[0];
  }

  static extractValidActivations(activationsResponse: ActivationsResponseDto): Array<ActivationDto> {
    const validActivations = activationsResponse.activations.filter(
      activation => activation.authorized && (activation.daysLeft === -1 || activation.daysLeft > 0),
    );
    return activationsResponse.errorCode === 'NO_ERROR' && validActivations.length > 0 ? validActivations : [];
  }

  static extractValidFeatureIds(activationsResponse: ActivationsResponseDto): Set<string> {
    const validActivations = this.extractValidActivations(activationsResponse);
    if (validActivations.length) {
      const featureIds = validActivations
        .map(activation => {
          const bestFeatureId = activation.featureId ? [activation.featureId] : [];
          const allFeatureIds = activation.allFeatureIds ? activation.allFeatureIds : [];
          return bestFeatureId.concat(allFeatureIds);
        })
        .reduce((a, b) => a.concat(b));
      return new Set(featureIds);
    } else {
      return new Set();
    }
  }

  private getActivationUrl(featureFilter: string): string {
    return `${this.getBaseUrl(this.stack)}/api/v1/activations?featureFilter=${featureFilter}`;
  }

  private async fetchActivations(featureFilter: string): Promise<Response> {
    return await fetch(this.getActivationUrl(featureFilter), {
      credentials: 'include',
      headers: [['Accept', 'application/json']],
    });
  }

  private getBaseUrl(stack: Stacks): string {
    switch (stack) {
      case Stacks.Dev:
        return 'https://dev-entitlements.sketchup.com';
      case Stacks.Stg:
        return 'https://stg-entitlements.sketchup.com';
      default:
        return 'https://entitlements.sketchup.com';
    }
  }
}
